import React from 'react';
import './credit.scss'; // Assuming the CSS is in this file
import { Link } from 'react-router-dom';

const cardData1 = [
  {
    id: 1,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 1',
    description1: 'This is the description for card 1.',
    description2: 'More details about card 1.',
    links: ""  
},
  {
    id: 2,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 2',
    description1: 'This is the description for card 2.',
    description2: 'More details about card 2.',
    links: ""  
},
  {
    id: 3,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 3',
    description1: 'This is the description for card 3.',
    description2: 'More details about card 3.',
    links: ""  
},
  {
    id: 4,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 4',
    description1: 'This is the description for card 4.',
    description2: 'More details about card 1.',
    links: ""  
},
];

const cardData2 = [
  {
    id: 4,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 4',
    description1: 'This is the description for card 4.',
    description2: 'More details about card 4.',
    links: ""
  },
  {
    id: 5,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 5',
    description1: 'This is the description for card 5.',
    description2: 'More details about card 5.',
    links: ""
  },
  {
    id: 6,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 6',
    description1: 'This is the description for card 6.',
    description2: 'More details about card 6.',
    links: ""
  },
  {
    id: 77,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 6',
    description1: 'This is the description for card 6.',
    description2: 'More details about card 6.',
    links: ""
  },
];

const cardData3 = [
  {
    id: 7,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 7',
    description1: 'This is the description for card 7.',
    description2: 'More details about card 7.',
    links: ""
  },
  {
    id: 8,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 8',
    description1: 'This is the description for card 8.',
   links: "",
    description2: 'More details about card 8.',
  },
  {
    id: 9,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 9',
    description1: 'This is the description for card 9.',
    description2: 'More details about card 9.',
    links: ""
  },
  {
    id: 11,
    image: 'https://via.placeholder.com/150',
    title: 'Card Title 9',
    description1: 'This is the description for card 9.',
    description2: 'More details about card 9.',
    links: ""
  },
];

const Credit = () => {
  const renderCards = (cardData) => {
    return cardData.map((card) => (
      <div className="card" key={card.id}>
        <img className="card-image" src={card.image} alt={card.title} />
        <div className="card-content">
          <h3 className="card-title">{card.title}</h3>
          <p className="card-description">{card.description1}</p>
          <p className="card-description">{card.description2}</p>
          <a href={card.links} className="apply-link">
            Apply Now <i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    ));
  };

  return (
    <div className="card-container">
      <h3 className="title">Credit Card <Link to="" className="me-auto" >More <i className="bi bi-arrow-right"></i></Link></h3>
      <div className="card-row">{renderCards(cardData1)}</div>
      <div className="card-row">{renderCards(cardData2)}</div>
      <div className="card-row">{renderCards(cardData3)}</div>
    </div>
  );
};

export default Credit;
