import React, { useState } from 'react';
import './Carousels.scss';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import B01 from './imgs/hamburger-01.png'
import B02 from './imgs/hamburger-02.png'
import B03 from './imgs/hamburger-03.png'
import B04 from './imgs/hamburger-04.png'
import B05 from './imgs/hamburger-05.png'
import B06 from './imgs/hamburger-06.png'
import B07 from './imgs/hamburger-07.png'
import B08 from './imgs/hamburger-08.png'
import B09 from './imgs/hamburger-09.png'
import B010 from './imgs/hamburger-10.png'
import { Container } from 'react-bootstrap';
const Slide = () => {
  // State to manage the current index of the slider
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of images and their content
  const slides = [
    {
      imgSrc:B010,
      title: 'MAGIC SLIDER',
      type: 'FLOWER',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.',
    },
    {
      imgSrc: B09,
      title: 'MAGIC SLIDER',
      type: 'NATURE',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.',
    },
    {
      imgSrc: B01,
      title: 'MAGIC SLIDER',
      type: 'PLANT',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.',
    },
    {
      imgSrc: B03,
      title: 'MAGIC SLIDER',
      type: 'NATURE',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.',
    },
  ];

  // Move slider to next or previous item
  const moveSlider = (direction) => {
    if (direction === 'next') {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    }
  };

  return (
    <Container className="slider">
      {/* Slider items */}
      <div className="list">
        <div className="item">
          <img src={slides[currentIndex].imgSrc} alt="" />
          <div className="content">
            <div className="title">{slides[currentIndex].title}</div>
            <div className="type">{slides[currentIndex].type}</div>
            <div className="description">{slides[currentIndex].description}</div>
            <div className="button">
              <button>SEE MORE</button>
            </div>
          </div>
        </div>
      </div>

      {/* Thumbnail */}
      <div className="thumbnail">
        {slides.map((slide, index) => (
          <div className="item" key={index}>
            <img src={slide.imgSrc} alt="" />
          </div>
        ))}
      </div>

      {/* Navigation arrows */}
      <div className="nextPrevArrows mt-2">
        <button className="prev" onClick={() => moveSlider('prev')} ><i className="bi bi-arrow-left"></i></button>
        <button className="next" onClick={() => moveSlider('next')} ><i className="bi bi-arrow-right"></i></button>
      </div>
    </Container>
  );
};

export default Slide;
