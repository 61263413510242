import React from "react";
import forgotimg from "../../assets/img/forgotpassword.png";
import "../login/login.scss"
import { Link } from "react-router-dom";
export function Forgot() {
  return (
    <div className="login-body">
      <div className="login-form">
        <h1> </h1>
        <div className="container">
          <div className="main">
            <div className="contact">
              <h2>Forgot Password</h2>
              <form action="" method="post">
                <input
                  type="text"
                  name=""
                  placeholder="User Name"
                  required
                  autoFocus=""
                />
                <input
                  type="email"
                  name=""
                  placeholder="Enter Your Email"
                  required
                  autoFocus=""
                />
                <button className="btn" type="submit">
                 submit
                </button>
              </form>
              <p className="account">Don't Have An Account? <Link to="/sinup">Register
              </Link></p>
              <p className="account"> <Link to="/login">Login
              </Link></p>
            </div>
            <div className="form-img">
              <img src={forgotimg} alt="form-img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
