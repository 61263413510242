import React from "react";
import sinup from "../../assets/img/sinup-1.png"
import "../login/login.scss"
import { Link } from "react-router-dom";
export default function Sinup() {
  return (
    <div className="login-body">
      <div className="login-form">
        <h1> </h1>
        <div className="container">
          <div className="main">
            <div className="contact">
              <h2>Sin Up</h2>
              <form action="" method="post">
                <input
                  type="text"
                  name=""
                  placeholder="User Name"
                  required
                  autoFocus=""
                />
                <input
                  type="email"
                  name=""
                  placeholder="email"
                  required
                  autoFocus=""
                />
                <input
                  type="number"
                  name=""
                  placeholder="Mobile Number"
                  required
                  autoFocus=""
                />
                <input
                  type="password"
                  name=""
                  placeholder="User password"
                  required
                  autoFocus=""
                />
                <button className="btn" type="submit">
                  Login
                </button>
              </form>
              <p className="account">If You Have A Account Click <Link to="/login" >Login
              </Link></p>
            </div>
            <div className="form-img">
              <img src={sinup} alt="form-img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
