import React from "react";
import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/img/Logo.png";
import { Link } from "react-router-dom";
import "./header.scss";
export function Header() {
  return (
    <div>
      <div className="">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home">
              <img src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <div className="home-menu">
                  <Link to="">Home</Link>
                  <Link to="">Presonal Loan</Link>
                  <Link to="">Credit Cards</Link>
                  </div>
                  <div>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}
