import React from "react";
import { Footer } from "../../layout/footer/footer";
import { Header } from "../../layout/header/header";
import "./home.scss";
import Slide from "./Carousals/Carousels";
import approval from "../../assets/img/approval (1).png";
import ddd from "../../assets/img/ddd.png";
import convertrewards from "../../assets/img/crm.png";
import service from "../../assets/img/services.png";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import WhatsAppChat from "../../layout/whatsapp/whatsapp";
import  Credit  from "./credit/credit";
import { PersonalLoan } from "../personalloan/personalloan";
export function Home() {
  return (
    <div className="main-home">
      <div className="main-header">
        <Header />
      </div>
      <div className="main-body">
        <div className="carousals">
          <Slide />
        </div>
        <Container className="">
          <div className="new-offers">
            <Row>
              <Col>
                <div className="promo-banner">
                  <div className="promo-content">
                    <h4>Everyday Fresh & Clean with Our Products</h4>
                    <button className="shop-now-btn">
                      Shop Now <span className="arrow">→</span>
                    </button>
                  </div>
                  <img
                    src={convertrewards}
                    alt="Fresh onions"
                    className="promo-image"
                  />
                </div>
              </Col>
              <Col>
                <div className="promo-banner">
                  <img
                    src={convertrewards}
                    alt="Fresh onions"
                    className="promo-image"
                  />
                  <div className="promo-content">
                    <h4>Everyday Fresh & Clean with Our Products</h4>
                    <button className="shop-now-btn">
                      Shop Now <span className="arrow">→</span>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
       
        <div className="">
        
          <Credit/>
        </div>
        <Container className="featuress">
          <Row className="features">
            <Col sm={12} md={3} lg={3} className="feature">
              <img
                style={{ width: "89px" }}
                className="features-imgs"
                src={convertrewards}
                alt=""
              />
              <h5>Convert Rewards to Cash</h5>
            </Col>
            <Col sm={12} md={3} lg={3} className="feature">
              <img
                style={{ width: "100px" }}
                className="features-imgs"
                src={ddd}
                alt=""
              />
              <h5 className="mt-3">Digital Online Process</h5>
            </Col>
            <Col sm={12} md={3} lg={3} className="feature">
              <img
                style={{ width: "100px" }}
                className="features-imgs"
                src={approval}
                alt=""
              />
              <h5 style={{ zIndex: "6" }}>Instant Approval</h5>
            </Col>
            <Col sm={12} md={3} lg={3} className="feature">
              <img
                style={{ width: "100px" }}
                className="features-imgs"
                src={service}
                alt=""
              />
              <h5>24/7 Support</h5>
            </Col>
          </Row>
        </Container>
      </div>
       <div >
        
       <Container>

        {/* <PersonalLoan /> */}
        </Container> 
        </div>
      <div className="main-footer">
        <Footer />
      </div>
      <WhatsAppChat />
    </div>
  );
}
