import { Login } from '../../authentication/login/login';
import  Sinup  from '../../authentication/sinup/sinup';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Forgot } from '../../authentication/forgot/forgot';
import { Home } from '../../components/homes/home';
export function Allroute() {
    const basePath = process.env.REACT_APP_BASE_PATH || '';
  return (
    <div className="app">
<Router basename="/">
      
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route  path='home' element={<Home/>}/>
        <Route path='login' element={<Login/>}/>
        <Route path='sinup' element={<Sinup/>}/>
        <Route path='forgot' element={<Forgot/>}/>
        {/* <Route path="/*" element={<NotFound/>}/> */}
      </Routes>
      
    
    </Router>
    </div>
  );
}