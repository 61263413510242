import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Allroute } from './layout/allroute/allroute';
function App() {
  return (
    <div >
    <Allroute/>
    </div>
  )
}

export default App;
