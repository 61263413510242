import React from 'react';
import whatsapp from "../../assets/img/whatsapp-logo.png"
const WhatsAppChat = () => {

  return (
    <div className='fixed-bottom right-100 p-3' style={{zIndex:"6", left:"auto"}}>
        <a href="https://wa.me/919959725652?text=Hello How can I Help You ?"target='_blank' className='ms-auto'>
        <img src={whatsapp} alt="whatsapp" style={{width:"100%",height:"70px"}}/>
        </a>
    </div>
  );
};

export default WhatsAppChat;