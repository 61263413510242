import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss"
export function Footer() {
  return (
    <div>
        <footer className="footer">
  	 <div className="container">
  	 	<div className="row">
  	 		<div className="footer-col">
  	 			<h4>company</h4>
  	 			<ul>
  	 				<li><Link to="#">about us</Link></li>
  	 				<li><Link to="#">Disclaimer</Link></li>
  	 				<li><Link to="#">privacy policy</Link></li>
  	 				<li><Link to="#">Terms and Conditions</Link></li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>get help</h4>
  	 			<ul>
  	 				<li><Link to="#">FAQ</Link></li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>Blog</h4>
  	 			<ul>
  	 				<li><Link to="#">watch</Link></li>
  	 			
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>follow us</h4>
  	 			<div className="social-links">
  	 				<Link to="#"><i className="bi bi-facebook"></i></Link>
  	 				<Link to="#"><i className="bi bi-instagram"></i></Link>
  	 				<Link to="#"><i className="bi bi-whatsapp"></i></Link>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
  </footer>
    </div>
  );
}